import React from "react";
import benefitImg1 from "../../../assets/images/outer/benefit_1.svg";
import benefitImg2 from "../../../assets/images/outer/benefit_2.svg";
import benefitImg3 from "../../../assets/images/outer/benefit_3.svg";
import { _t } from "../../Common/components/InjectIntlContext";
import CustomButton from "../../Common/components/CustomButton";
import { Typography } from "antd";
import BenefitCardItem from "./BenefitCardItem";

const slides = (_t: any) => [
  {
    title: _t("benefit_1"),
    icon: benefitImg1,
  },
  {
    title: _t("benefit_2"),
    icon: benefitImg2,
  },
  {
    title: _t("benefit_3"),
    icon: benefitImg3,
  },
];

export type ourBenefitsProps = {
  getStarted: () => void;
};

const OurBenefits: React.FC<ourBenefitsProps> = ({ getStarted }) => {
  const benefitCards = slides(_t);
  const benefit = (card, idx) => (
    <li className="benefits-grid__item">
      <BenefitCardItem info={card} key={card.title + idx} />
    </li>
  );

  return (
    <section className="our-benefits custom-section">
      <div className="container">
        <h2 className="custom-section__title">
          {_t("our_benefits")}
        </h2>
        <ul className="benefits-grid">{benefitCards.map(benefit)}</ul>
        <div className="get-started">
          <CustomButton
            onClick={getStarted}
            btnLabel={_t("get_started")}
          />
        </div>
      </div>
    </section>
  );
};

export default OurBenefits;
