import React from "react";
import { Collapse, Button, Row, Col } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import classnames from "classnames";
import Form from "antd/lib/form/Form";
import { _t } from "./InjectIntlContext";

export function Filters({
  mainFilters,
  expandableFilters = null,
  onApply,
  initialValues,
  form = undefined,
}) {
  return (
    <Form initialValues={initialValues} onFinish={onApply} form={form}>
      <Collapse
        className="filters"
        expandIconPosition="right"
        expandIcon={() => (
          <Button type="ghost" shape="circle" icon={<FilterOutlined />} />
        )}
      >
        <Collapse.Panel
          header={
            <div
              onClick={(e) => e.stopPropagation()}
              className={classnames(
                "filters-main",
                !!expandableFilters && "filters-main-expandable"
              )}
            >
              <Row align="middle">
                <Col flex="auto">{mainFilters}</Col>
                <Col flex="0 0 auto">
                  <Button
                    shape="round"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "10px" }}
                  >
                    {_t("filter")}
                  </Button>
                </Col>
              </Row>
            </div>
          }
          key="2"
          showArrow={!!expandableFilters}
        >
          {expandableFilters}
        </Collapse.Panel>
      </Collapse>
    </Form>
  );
}
