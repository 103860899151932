import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../Auth/actions";
import Layout from "antd/lib/layout";
import Menu from "antd/lib/menu";
import { SidebarItem } from "./SidebarItem";
import classNames from "classnames";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ReactComponent as IconLogoMini } from "../../../images/icons/sidebar/logo_mini.svg";
import { ReactComponent as IconLogo } from "../../../images/icons/sidebar/logo.svg";
import { sidebarItems } from "./constants";
import { _t } from "../../Common/components/InjectIntlContext";
import { NavLink } from "react-router-dom";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { updateLocale } from "../../Locales/actions";
import {
  getItem,
  getLocale,
  setItem,
} from "../../../system/helpers/localstorageHelper";
import { flags } from "../../Locales/constants";
import { UserProfilePreview } from "../../../pages/inner/components";

const { Sider } = Layout;
const Sidebar = () => {
  const isCollapsed = JSON.parse(getItem("isSidebarCollapsed"));
  const [collapsed, setCollapse] = useState(!!isCollapsed);
  const dispatch = useDispatch();
  const { languages } = useSelector((state) => state.locale);
  const locale = getLocale();
  const language = Object.values(languages).filter(
    (item, index) => languages[index].code === locale
  )[0];

  const logout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(logoutAction());
  };

  const languageSubMenu = (languages, dispatch) => {
    return Object.values(languages).map((language) => ({
      title: language.name,
      code: language.code,
      path: `change-language/${language.code}`,
      onClick: (code) => {
        updateLocale(code)(dispatch);
      },
      icon: (
        <img
          alt=""
          src={flags[language.code]}
          className={"sidebar-language-flag"}
        />
      ),
    }));
  };

  const toggleSidebar = () => {
    setCollapse(!collapsed);
    setItem("isSidebarCollapsed", !collapsed);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      style={{ background: "#fff" }}
      trigger={null}
      width={256}
      className="sidebar"
    >
      <NavLink
        to={
          getItem("role") === "admin"
            ? APP_ROUTES.dashboard.admin
            : APP_ROUTES.dashboard.general
        }
        className={classNames("sidebar-logo", {
          "sidebar-logo--collapsed": collapsed,
        })}
      >
        {collapsed ? <IconLogoMini /> : <IconLogo />}
      </NavLink>
      <UserProfilePreview logout={logout} collapsed={collapsed} />

      <Menu mode="inline">
        {sidebarItems.map((item, key) => {
          const userZone = item.path === "/user";
          const languageZone = item.section === "languages";
          const allComponents = item.path === "/all-components";

          if (allComponents && process.env.NODE_ENV !== "development") {
            return null;
          }
          let subMenu = item.subMenu;
          let title = _t(item.title);
          let sidebarItemIcon = item.icon;
          if (languageZone) {
            if (languages.length <= 1) {
              return null;
            }
            subMenu = languageSubMenu(languages, dispatch);
            title = language ? language.code.toUpperCase() : title;
            sidebarItemIcon = null;
          }

          return (
            <SidebarItem
              key={key}
              itemTitle={title}
              itemClassName={classNames("sidebar__text", {
                "sidebar__text__margin-left": !collapsed && !userZone,
                "sidebar__text-username": !collapsed && userZone,
              })}
              menuDivider={item.menuDivider}
              sidebarItemIcon={sidebarItemIcon}
              path={item.path}
              userZone={userZone}
              collapsed={collapsed}
              logout={logout}
              subMenu={subMenu}
              item={item}
              languageZone={languageZone}
            />
          );
        })}
      </Menu>
      <button
        type="button"
        className="sidebar__trigger"
        data-testid="sidebar-trigger"
        onClick={toggleSidebar}
      >
        {collapsed ? <RightOutlined /> : <LeftOutlined />}
      </button>
    </Sider>
  );
};
export default Sidebar;
